


.card-element {
    /* height: 15rem; */
    width: 100%;
    border-radius: 21px;
    background-color: #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 3%;
    transition: .3s ease-in-out;
    box-sizing: border-box;
    transition: all .3s ease-in-out;
}

.card-invalid {
    border: .3px solid rgba(255, 0, 0, .5 ) !important;
    background-color: rgba(255, 0, 0, 0.1);
    animation: wiggle 1s forwards;
}

.bank-name {
    font-weight: bolder;
    font-size: 12pt;
}

@keyframes wiggle {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
      }
    
      20%, 80% {
        transform: translate3d(2px, 0, 0);
      }
    
      30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
      }
    
      40%, 60% {
        transform: translate3d(4px, 0, 0);
      }
}

.sorter {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.card-logo {
    height: 40px;
    width: 60px;
    background-image: url("../../assets/images/card_logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.input-handler {
    direction: ltr;
    /* margin: 0px 10px; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.exp-cvc {
    margin-top: 10px;
    width: 100%;
}
.cvc-handler {
    margin-top: 10px;
    width: 100%;
}

.exp-handler {
    direction: ltr;
    /* margin: 0px 10px; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.card-item {
    background-color: white;
    width: 100%;
}