@font-face {
  font-family: Shabnam;
  src: url("./assets/fonts/Shabnam.ttf");
}


.App {
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: white;
  background-image: url("./assets/images/bg.jpg");
  background-position: center;
  background-size: cover;
  font-family: Shabnam !important;
}

.ant-message-notice > *{
  font-family: Shabnam !important;
}


body, html {
  margin: 0;
  padding: 0;
  background-color: white;
}





/* Sized Boxes */
.sized-box-width-10 {
  width: 10px;
}
.sized-box-width-20 {
  width: 20px;
}
.sized-box-width-40 {
  width: 40px;
}
.sized-box-width-50 {
  width: 50px;
}




.mobile-padding {
  /* Extended mobile view padding */
  height: 0%;
}
.sized-box-height-10 {
  height: 10px;
}
.sized-box-height-20 {
  height: 20px;
}
.sized-box-height-40 {
  height: 40px;
}

.sized-box-height-50 {
  height: 50px;
}

::-webkit-scrollbar {
  display: none;
  }

/* Buttons */
.button-element {
  color: #fff;
  font-family: Shabnam;
  font-weight: bold;
  background-color: rgba(252, 152, 47, 1);
  border: none;
  outline: none;
  height: 60px;
  width: 40%;
  cursor: pointer;
  border-radius: 14px;
  transition: .6s ease-in-out;
}

.button-element:hover {
  opacity: 0.3;
}
.button-element:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}


/* Font Sizes */
.title-style {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 14pt;
}
.title-low-sized {
  margin: 0;
  padding: 0;
  font-weight: lighter;
  font-size: 9pt;
}
.bold {
  font-weight: bolder;
}
.rtl-align {
  text-align: right !important;
  padding-right: 20px;
}

/* TextField Styles */

.input-style {
  border: none;
  height: 60px;
  width: 100%;
  border-radius: 14px;
  text-align: center;
  font-weight: bold;
  font-family: Shabnam;
  color: grey;
  background-color: #f1f1f1;
}
.input-style:focus {
  border: none;
  outline: none;
}
.input-style:invalid {
  border: 1px solid rgba(255, 0, 0, 0.3);
  outline: none;
}



/* Circle Avatar */
.circle-avatar {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #f1f1f1;
  background-size: contain !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}



/* Transaction Status Indicator */
.expired {
  color: red !important;
}

.pending {
}
.completed {
  color: green !important;
}


/* 
##Device = Desktops
##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  /* CSS */
  
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
  /* CSS */
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  /* CSS */
  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  /* CSS */
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .title-style {
    font-size: 11pt;
  }
  .input-style {
    height: 50px;
  }
  .button-element {
    height: 50px;
  }
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  /* CSS */
  .title-style {
      font-size: 11pt;
  }
  .input-style {
    height: 50px;
  }
  .mobile-padding {
    /* Extended mobile view padding */
    height: 40px;
  }
  .button-element {
    height: 50px;
  }
}