

.home-wrapper {
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    height: 100vh;
    overflow: hidden;
    width: 100%;
    flex: 80% 20% auto;
    flex-direction: row;
    justify-content: flex-start;
}


.drawer {
    overflow-y: hidden;
    padding: 10px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: hidden;
    width: 30%;
    height: 100%;
    max-height: 100%;
    background-color: white;
    z-index: 1000;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, .15);
}

.drawer-wrapper {
    width: 100%;
}

.mobile-drawer-title {
  visibility: hidden;
}

.drawer-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.drawer-item {
    height: 5rem;
    width: 100%;
    display: flex;
    /* margin: 2px 0px; */
    justify-content: flex-start;
    padding-right: 5%;
    font-weight: bold !important;
    align-items: center;
    cursor: pointer;
    background-color: #f1f1f1;
    transition: all .3s ease-in-out;
}
.drawer-item-focused {
    background-color: rgba(252, 152, 47, .3)
}

.icon {
    height: 20px;
    width: 20px;
}

.info {
    background-image: url("../../../assets/images/transaction.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
    color: black;
    height: 20px;
    width: 20px;
}

.setting {
    background-image: url("../../../assets/images/settings.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
    color: black;
    height: 20px;
    width: 20px;
}


.info_round {
    background-image: url("../../../assets/images/info_round.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
    color: black;
    height: 20px;
    width: 20px;
}


.logout {
    background-image: url("../../../assets/images/logout.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
    color: black;
    height: 20px;
    width: 20px;
}


.statistics {
    background-image: url("../../../assets/images/statistics.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
    color: black;
    height: 20px;
    width: 20px;
}

.content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: white;
}





/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
   /* CSS */
   .drawer-wrapper {
      width: 100%;
      position: absolute;
      display: flex;
      flex-direction: row;
      bottom: 0;
    }
    .drawer {
        padding: 10px 0px;
        box-sizing: border-box;
        position: fixed;
        bottom: 0;
        display: flex;
        overflow-y: hidden;
        width: 100%;
        height: 80px;
        background-color: white;
    }
    .drawer-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0;
    }
    .drawer-title {
        position: absolute;
        visibility: hidden;
    }
    .content {
      padding-bottom: 25% !important;
      padding-top: 15% !important;
    }
    .mobile-drawer-title {
      visibility: visible;
      position: fixed;
      top: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      padding: 10px;
      width: 100vw;
      height: 60px;
      background-color: white;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    }
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    /* CSS */
    .drawer-wrapper {
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: row;
        bottom: 0;
    }
    .drawer {
        padding: 10px 0px;
        box-sizing: border-box;
        position: fixed;
        bottom: 0;
        display: flex;
        overflow-y: hidden;
        width: 100%;
        height: 80px;
        background-color: white;
    }
    .drawer-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0;
    }
    .drawer-title {
        position: absolute;
        visibility: hidden;
    }
    .content {
      padding-bottom: 25% !important;
      padding-top: 15% !important;
    }
    .mobile-drawer-title {
      visibility: visible;
      position: fixed;
      top: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      padding: 10px;
      width: 100vw;
      height: 60px;
      background-color: white;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    }
    
  }