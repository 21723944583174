


.transactions-wrapper {
    box-sizing: border-box;
    padding-right: 20px;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: visible;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  
  
.scroller-widget {
  padding-bottom: 0%;
}


  .transaction-viewer {
    width: 90%;
    height: 100vh;
    overflow-y: scroll;
    overflow-clip-margin: 0;
    padding-bottom: 5%;
}

.transaction-item {
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    padding: 10px 40px;
    border-radius: 21px;
    width: 100%;
    height: 100px;
    background-color: #f1f1f1;
    /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.04); */
    transition: all .3s ease-in-out;
}

.transaction-item:hover {
    /* box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1); */
    transform: translate(0px, -2px);
}

.transaction-title {
    display: flex;
    flex-direction: column;
}





/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    /* CSS */
    .transaction-viewer {
        width: 90%;
        height: 100%;
        padding-bottom: 15%;
    }
    .scroller-widget {
      padding-bottom: 15%;
    }
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    /* CSS */

    .scroller-widget {
      padding-bottom: 15%;
    }
    .transaction-viewer {
        width: 90%;
        height: 100%;
        padding-bottom: 15%;
    }
    
  }