


.gateway-setting {
    padding-right: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}


.gateway-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}


.gateway-edit-wrapper {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding: 2%;
    background-color: white;
    border-radius: 21px;
    justify-content: center;
    align-items: center;
    width: 90%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
}


.row-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}


.row-detail-compact {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
}


.text-button {
    cursor: pointer;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    color: rgba(252, 152, 47, 1) !important;
    font-weight: bolder !important;
    background-color: rgba(252, 152, 47, 0.1);
    border: none;
    outline: none;
    transition: all .3s ease-in-out;
}
.text-button:hover {
    color: white !important;
    background-color: rgba(252, 152, 47, 1);
}


.key-wrapper {
  filter: blur(3px);
  background-color: #f1f1f1;
  padding: 10px 40px;
  border-radius: 9px;
  transition: .3s linear;
}
.key-wrapper:hover {
  filter: blur(0px);
}


.card-wrapper {
  /* width: 100%; */
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    /* CSS */
    .gateway-edit-wrapper {
        padding: 5%;
        width: 100%;
    }
    .gateway-setting {
        padding: 20px;
    }
    .row-detail {
        display: flex;
        flex-direction: column-reverse;
    }
    .row-detail-compact > .text-button {
      margin-top: 10px;
    }
    .row-detail-compact {
        display: flex;
        width: 100%;
        flex-direction: column-reverse;
    }
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    .gateway-edit-wrapper {
        padding: 5%;
        width: 100%;
    }
    .gateway-setting {
        padding: 20px;
    }
    .row-detail {
        display: flex;
        flex-direction: column-reverse;
    }
    .row-detail-compact > .text-button {
      margin-top: 10px;
    }
    .row-detail-compact {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }
  }