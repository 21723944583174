


.monthly-payment-chart {
    width: 90%;
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, .05);
    border-radius: 21px;
}

.row-item-box {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    border-radius: 21px;
}

.row-item {
    width: 30%;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 21px;
}

.chart-view {
    background-color: #f1f1f1;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 21px;
}




/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    /* CSS */
    .row-item-box {
        flex-direction: column;
    }
    .row-item {
        width: 100%;
        margin-bottom: 5px;
    }
    .monthly-payment-chart {
        width: 100%;
    }
    
  }